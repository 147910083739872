import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useRouter } from 'next/router';

import { i18n, Link, withTranslation } from '../../../common/helpers/i18n';
import combineStyles from '../../../common/helpers/style';
import { generalStyles } from '../../../common/styles/generalStyles';
import { menuStyles } from './menuStyles';
import Logo from '../logo/logo';
import LoginHeader from '../loginHeader/loginHeader';
import {FIND_TUTORS_ROUTE, BECOME_TUTOR_ROUTE, ABOUT_ROUTE, STUDENT_ROUTES, FIND_STUDENTS_ROUTE} from '../../routes';
import { isCurrentRoute } from '../../helpers/general';

const Menu = (props) => {
  const router = useRouter();
  const { t, classes } = props;

  return (
    <Grid container className={classes.menu}>

      <Grid className={classes.logoContainer}>
        <Logo className="main" />
      </Grid>

      <List className={classes.topList}>
        <Link href={FIND_TUTORS_ROUTE}>
          <a>
            <ListItem className={`${isCurrentRoute(router, FIND_TUTORS_ROUTE) ? classes.active : ''}`}>{t('Find tutors')}</ListItem>
          </a>
        </Link>
        {/*<Link href={FIND_STUDENTS_ROUTE}>*/}
        {/*  <a>*/}
        {/*    <ListItem className={`${isCurrentRoute(router, FIND_STUDENTS_ROUTE) ? classes.active : ''}`}>{t('Find students')}</ListItem>*/}
        {/*  </a>*/}
        {/*</Link>*/}
      </List>
      <LoginHeader />
    </Grid>
  );
};

Menu.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});
const combinedStyles = combineStyles(generalStyles, menuStyles);

export default withTranslation('common')(withStyles(combinedStyles)(Menu));
