import { themeVariables } from '../../styles/themeVariables';

export const footerStyles = theme => ({
  container: {
    // position: 'absolute',
    bottom: 0,
  },
  mainContainer: {
    backgroundColor: themeVariables.colors.primaryText,
    paddingBottom: '24px',
    paddingTop: '60px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      textAlign: 'center',
    },
  },
  secondContainer: {
    backgroundColor: '#173054',
    minHeight: '28px',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    color: themeVariables.colors.paleGray,
    paddingBottom: '16px',
    fontWeight: 'bold',
  },
  link: {
    color: themeVariables.colors.tetriaryText,
    fontSize: '16px',
    lineHeight: '36px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    textDecoration: 'none',
  },
  textContainer: {
    textAlign: 'center',
  },
  inc: {
    color: '#919ca9',
  },
  contactContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  socialContainer: {
    marginTop: '16px',
    marginBottom: '12px',
    justifyContent: 'center',
  },
  logo: {
    width: '135px',
    height: '60px',
    cursor: 'pointer',
  },
  facebookContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor: '#0060a8',
  },
  youtubeContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    marginLeft: '16px',
    backgroundColor: '#d44545',
  },
  instagramContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    marginLeft: '16px',
    backgroundImage: 'linear-gradient(225deg, #261eff, #e03f20 44%, #f7f600)',
  },
  socialImg: {
    width: '24px',
    height: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    display: 'flex',
  },
  phone: {
    color: themeVariables.colors.paleGray,
    fontWeight: 'bold',
  },
  section: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
});
