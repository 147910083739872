import { themeVariables } from '../../styles/themeVariables';

export const menuStyles = theme => ({
  menu: {
    display: 'flex',
    height: '64px',
    backgroundColor: themeVariables.colors.mainPale,
  },
  logoContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 0 0 20px',
  },
  topList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 3,
    '& a': {
      textDecoration: 'none',
    },
    '& li': {
      display: 'inline',
      color: themeVariables.colors.white,
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  active: {
    color: `${themeVariables.colors.brightYellow} !important`,
  },
});
