import React from 'react';
import { Container, Typography, withStyles, Grid } from '@material-ui/core';

import { i18n, Link, withTranslation } from '../../helpers/i18n';
import { footerStyles } from './footerStyles';
import {
  FIND_TUTORS_ROUTE,
  HOMEPAGE_ROUTE,
  REGISTER_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  COOKIE_POLICY_ROUTE,
  FIND_STUDENTS_ROUTE,
  CONTACT_ROUTE
} from '../../routes';

const Footer = ({ t, classes }) => {
  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.mainContainer}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.section}>
              <Typography variant="subtitle1" className={classes.title}>{t('For Students')}</Typography>
              <Grid>
                <Link href={FIND_TUTORS_ROUTE}>
                  <a className={classes.link}>{t('Find a private tutor')}</a>
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.section}>
              <Typography variant="subtitle1" className={classes.title}>{t('For Tutors')}</Typography>
              <Grid>
                <Link href={REGISTER_ROUTE}>
                  <a className={classes.link}>{t('Become a tutor')}</a>
                </Link>
              </Grid>
              {/*<Grid>*/}
              {/*  <Link href={FIND_STUDENTS_ROUTE}>*/}
              {/*    <a className={classes.link}>{t('Find students')}</a>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.section}>
              <Typography variant="subtitle1" className={classes.title}>{t('Other')}</Typography>
              <Grid>
                <Link href={COOKIE_POLICY_ROUTE}>
                  <a className={classes.link}>{t('Cookie Policy')}</a>
                </Link>
              </Grid>
              <Grid>
                <Link href={TERMS_OF_SERVICE_ROUTE}>
                  <a className={classes.link}>{t('Terms of service')}</a>
                </Link>
              </Grid>
              <Grid>
                <Link href={CONTACT_ROUTE}>
                  <a className={classes.link}>{t('Contact Support')}</a>
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.contactContainer}>
              <Link href={HOMEPAGE_ROUTE}>
                <img src="/images/logo-color.png" alt="Logo" className={classes.logo} />
              </Link>
              <Grid container className={classes.socialContainer}>
                <Grid className={classes.facebookContainer}>
                  <a href="https://www.facebook.com/lerntutoring" target="_blank">
                    <img src="/images/ic-soc-fb.png" alt="Facebook" className={classes.socialImg} />
                  </a>
                </Grid>
              </Grid>
              {/* <Typography variant="body1" className={classes.phone}></Typography> */}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container className={classes.secondContainer}>
        <Container fixed>
          <Grid item xs={12} className={classes.textContainer}>
            <Typography variant="body2" className={classes.inc}>© 2020-2021 LERN Inc.</Typography>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

Footer.getInitialProps = async () => ({
  namespacesRequired: ['footer'],
});

export default withStyles(footerStyles, { withTheme: true })(withTranslation('footer')(Footer));
