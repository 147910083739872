import { themeVariables } from '../../styles/themeVariables';

export const loginHeaderStyles = theme => ({
  loginHeaderWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px 0 0',
    fontSize: '18px',
    '& a': {
      textDecoration: 'none',
      color: themeVariables.colors.white,
    },
  },
  loginIcon: {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: 'url("/images/ic-login.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    margin: '0 10px 0 0',
  },
  loginTxt: {
    display: 'block',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  registerTxt: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sandwich: {
    display: 'none',
    color: themeVariables.colors.white,
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  drawerList: {
    padding: '20px',
    '& a': {
      color: themeVariables.colors.primaryText,
      textDecoration: 'none',
    },
  },
  accountIcon: {
    fontSize: '30px',
    margin: '3px 10px 0 0'
  },
  signUp: {
    marginLeft: 10
  }
});
