import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';

import { Link, withTranslation } from '../../../common/helpers/i18n';
import combineStyles from '../../../common/helpers/style';
import { generalStyles } from '../../../common/styles/generalStyles';
import { loginHeaderStyles } from './loginHeaderStyles';
import {
  FIND_TUTORS_ROUTE,
  LOGIN_ROUTE,
  FIND_STUDENTS_ROUTE, REGISTER_ROUTE
} from '../../routes';
import { getApiImages, getAvatarLetters } from '../../../common/helpers/general';
import { logoutAction } from '../../actions/authentication';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TopBarProfile from "../topBarProfile/topBarProfile";
import Toolbar from "@material-ui/core/Toolbar";

const LoginHeader = (props) => {
  const { t, classes, user, logout } = props;

  const [state, setState] = React.useState({
    mainDrawer: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const avatarLetters = getAvatarLetters(props.user.details.first_name + ' ' + props.user.details.last_name);

  return (
    <Grid className={classes.loginHeaderWrapper}>

      {
        user.isLoggedIn
          ? null
          : (
            <>
              <Link href={LOGIN_ROUTE}>
                <a>
                  <AccountCircleIcon className={classes.accountIcon} />
                </a>
              </Link>
              <Link href={LOGIN_ROUTE}>
                <a>
                  <Grid className={classes.loginTxt}>{t('Log In')}</Grid>
                </a>
              </Link>
              <Link href={REGISTER_ROUTE}>
                <a>
                  <PersonAddIcon className={classes.accountIcon} />
                </a>
              </Link>
              <Grid>
                <Link href={REGISTER_ROUTE}>
                  <a>
                    <Grid className={classes.registerTxt}>{t('Sign Up')}</Grid>
                  </a>
                </Link>
              </Grid>
            </>
          )
      }

      <MenuIcon className={classes.sandwich} onClick={toggleDrawer('mainDrawer', true)} />

      {
        user.isLoggedIn
          ? (
            <TopBarProfile userDetails={user.details} />
          )
          : (
            <></>
          )
      }

      <SwipeableDrawer
        anchor="right"
        open={state.mainDrawer}
        onClose={toggleDrawer('mainDrawer', false)}
        onOpen={toggleDrawer('mainDrawer', true)}
      >
        <List className={classes.drawerList}>
          <Link href={FIND_TUTORS_ROUTE}>
            <a>
              <ListItem>{t('Find tutors')}</ListItem>
            </a>
          </Link>
          {/*<Link href={FIND_STUDENTS_ROUTE}>*/}
          {/*  <a>*/}
          {/*    <ListItem>{t('Find students')}</ListItem>*/}
          {/*  </a>*/}
          {/*</Link>*/}
          <Link href={REGISTER_ROUTE}>
            <a>
              <ListItem>{t('Become a tutor')}</ListItem>
            </a>
          </Link>
          {
            user.isLoggedIn
              ? (
                <Link href="">
                  <a onClick={logout}>
                    <ListItem>{t('Logout')}</ListItem>
                  </a>
                </Link>
              )
              : (
                <div>
                  <Link href={LOGIN_ROUTE}>
                    <a>
                      <ListItem>{t('Log In')}</ListItem>
                    </a>
                  </Link>
                  <Link href={REGISTER_ROUTE}>
                    <a>
                      <ListItem>{t('Sign Up')}</ListItem>
                    </a>
                  </Link>
                </div>
              )
          }
        </List>
      </SwipeableDrawer>
    </Grid>
  );
};

LoginHeader.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutAction());
    },
  };
};
const combinedStyles = combineStyles(generalStyles, loginHeaderStyles);

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)((withStyles(combinedStyles)(LoginHeader))));
